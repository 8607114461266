<template>
  <div class="h-100">
    <v-row class="h-100 ma-0" no-gutters>
      <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown"
             class="h-100 d-flex justify-center align-center mr-10 background--yankee-blue">
        <v-img min-width="50px" contain src="@/assets/Logo_S2.png">
        </v-img>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndDown" cols="1"></v-col>
      <v-col class="d-flex align-center pr-16">
        <v-card flat color="body-background">
          <v-row v-if="!$vuetify.breakpoint.smAndDown" class="ma-12"></v-row>
          <v-row>
            <v-card-title>
              <v-row class="align-baseline">
                <h3>
                  <span class="grey--text">Welkom bij</span>
                </h3>
                <v-img :width="$vuetify.breakpoint.smAndDown ? '160px' : '320px'" contain src="@/assets/Logo_blauw.png"
                       :class="$vuetify.breakpoint.smAndDown ? 'ml-1' : 'ml-n7'"/>
              </v-row>
            </v-card-title>
            <v-card-text class="mt-4 mb-4 pa-1">
              <h2>
                Wachtwoord vergeten
              </h2>
            </v-card-text>
            <div class="w-100" v-if="! submitted">
              <v-card-text class="pt-4">
                <v-form v-model="valid" v-on:submit.prevent>
                  <v-row justify="center" :class="{'w-75' : !$vuetify.breakpoint.smAndDown }">
                    <v-col class="pa-0">
                      <v-text-field autofocus outlined filled persistent-placeholder type="email" v-model.trim="email"
                                    label="Email"
                                    :rules="emailRules" required @keyup.native.enter="forgotPassword"/>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="d-block pt-0">
                <div class="mb-4">
                  <v-btn type="button" color="secondary" @click="forgotPassword">
                    Vernieuwen
                  </v-btn>
                </div>
                <div class="text-body-2 mb-2">
                  <span>Toch aanmelden?</span>
                  <router-link :to="{name: routeNames.LOGIN}" class="pl-1 text-decoration-none">
                    Klik hier.
                  </router-link>
                </div>
                <div class="text-body-2">
                  <span>Nieuw bij Buildbase?</span>
                  <router-link :to="{name: routeNames.PAYMENT}" class="pl-1 text-decoration-none">
                    Registreer.
                  </router-link>
                </div>
              </v-card-actions>
            </div>
            <div v-else>
              <div>
                Er is een e-mail verstuurd naar uw mailadres met instructies om uw wachtwoord te vernieuwen.
              </div>
              <div class="text-body-2 mb-2 mt-2">
                <router-link :to="{name: routeNames.LOGIN}" class="text-decoration-none">
                  Opnieuw aanmelden.
                </router-link>
              </div>
            </div>
          </v-row>
          <v-row class="ma-16"></v-row>
        </v-card>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="4"></v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RouteNames from "@/router/RouteNames";
import {getEmailRules} from "@/shared/utils/inputRulesUtils"

export default {
  name: "ForgotPassword",
  data() {
    return {
      valid: false,
      submitted: false,
      email: null,
      emailRules: getEmailRules(),
      routeNames: RouteNames
    }
  },
  methods: {
    forgotPassword() {
      if (this.valid) {
        const user = {
          email: this.email.toLowerCase(),
          resetBaseUrl: window.location.origin + "/password-reset-token"
        }
        this.$store.dispatch("authModule/forgotPassword", user)
            .then(() => {
              this.submitted = true;
            })
      }
    },
  }
}
</script>

<style lang="scss">
div.v-input.v-text-field--outlined.v-text-field--filled {
  div.v-input__slot {
    input {
      margin-top: 12px;
    }
  }
}
</style>
